import { useDomainContext } from "@hooks/useDomainContext";
import { ReactElement } from "react";

import Section from "./section";

export default function Page(): ReactElement {
    const { routeConfig } = useDomainContext();

    if (!routeConfig || !routeConfig.config?.sections) {
        return <></>;
    }

    return (
        <div className="flex flex-col">
            {routeConfig.config?.sections.map((section, index: number) => {
                return (
                    <Section
                        key={index}
                        variables={section.variables ?? {}}
                        components={section.components}
                        sectionIndex={index}
                    />
                );
            })}
        </div>
    );
}
